import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavigationComponent } from './components/navigation/navigation.component';
import { LogoComponent } from './components/logo/logo.component';
import { StickyHeaderComponent } from './components/sticky-header/sticky-header.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { HttpClientModule } from '@angular/common/http';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
// import { NgxPageScrollModule } from 'ngx-page-scroll';

@NgModule({
  declarations: [
    NavigationComponent,
    LogoComponent,
    StickyHeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPageScrollCoreModule,
    HttpClientModule,
    NgxHideOnScrollModule
  ],
  exports: [
    NavigationComponent,
    LogoComponent,
    StickyHeaderComponent,
    HttpClientModule,
    NgxHideOnScrollModule
  ]
})
export class SharedModule { }
