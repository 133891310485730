<svg class="w-40" viewBox="0 0 212 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.8878 4.7186C32.1069 4.30797 31.1454 4 30.0225 4C28.0414 4 25.5375 4.96498 22.6142 8.01734L17.6554 13.1927C17.639 13.1736 17.622 13.1549 17.6045 13.1365L10.8931 6.12154C10.8576 6.0839 10.8205 6.04626 10.7834 6.00862C10.7463 5.97097 10.7092 5.93333 10.6737 5.89569C9.47339 4.70486 7.95042 4.08207 6.38873 4.08207C5.5498 4.08207 4.69152 4.26001 3.87196 4.63642C1.48425 5.70406 0 8.0515 0 10.7548V39.0952C0 41.0594 0.806658 42.9004 2.21992 44.146C4.13009 45.8227 8.16983 47.7527 13.8422 41.8191L18.7507 36.6785L25.5762 43.7833C25.6117 43.821 25.6488 43.8586 25.6859 43.8962C25.723 43.9339 25.7602 43.9715 25.7956 44.0092C27.6477 45.8502 30.2678 46.3361 32.6297 45.2753C35.0238 44.2076 36.5145 41.8534 36.5145 39.1432V10.9876C36.5145 8.339 35.0883 5.87522 32.8878 4.7186ZM20.7116 34.6247L27.538 41.7439C27.5961 41.8054 27.6541 41.8601 27.7122 41.9149L27.7123 41.9149C28.7641 42.9552 30.1968 43.2153 31.539 42.6199C32.6038 42.1408 33.7396 40.9773 33.7396 39.15V10.9876C33.7396 9.42718 32.9136 7.98312 31.6423 7.30558C29.6999 6.279 27.3251 7.20292 24.5824 10.0637L11.5727 23.6419C11.0306 24.2099 10.1529 24.2099 9.61086 23.6419C9.06879 23.0738 9.06879 22.1567 9.61086 21.5887L15.7 15.2335C15.6828 15.2172 15.6658 15.2003 15.6492 15.1828L8.93777 8.16101C8.87969 8.09941 8.82161 8.04466 8.76353 7.98991C7.71165 6.94964 6.29193 6.68958 4.94965 7.29184C3.89132 7.77091 2.75554 8.92752 2.75554 10.7548V39.0952C2.75554 40.2039 3.20727 41.2374 3.99457 41.9286C6.09188 43.7764 8.74417 43.0441 11.8805 39.7659L24.8257 26.2219C25.3678 25.6539 26.239 25.6539 26.781 26.2219C27.3231 26.79 27.3231 27.7002 26.781 28.2683L20.7116 34.6247Z" fill="#1371FF"/>
    <path d="M68.6472 15.1909C66.0518 15.1909 64.0068 16.0174 62.4732 17.5523V15.7812H57.2822V35.4202H62.4732V26.7224C62.4732 21.9209 64.4001 19.953 67.3495 19.953C69.7483 19.953 71.3213 21.8028 71.3213 24.5578V35.4202H76.5123V23.4558C76.5123 18.5756 73.3663 15.1909 68.6472 15.1909Z" fill="white"/>
    <path d="M100.037 25.2268C99.8404 19.2446 95.318 15.1909 90.0878 15.1909C84.4643 15.1909 79.9419 19.4414 79.9419 25.6204C79.9419 31.7994 84.4643 36.0106 90.0091 36.0106C94.2956 36.0106 97.7955 34.0427 99.5258 29.9496L94.8855 28.9657C93.4698 31.3665 91.5428 31.5633 90.0091 31.5633C87.6103 31.5633 85.6047 29.8316 85.0935 26.9979H100.037V25.2268ZM90.0878 19.7169C92.172 19.7169 94.0596 20.8189 94.7282 23.3377H85.2508C85.88 20.8976 88.1215 19.7169 90.0878 19.7169Z" fill="white"/>
    <path d="M116.762 30.2251C115.739 30.7761 114.402 31.1697 113.498 31.1697C111.767 31.1697 110.627 30.1464 110.627 27.785V20.0318H117.037V15.7812H110.627V9.83838L105.436 11.5602V15.7812H101.228V20.0318H105.436V28.1786C105.436 33.5704 108.503 36.0106 113.144 36.0106C114.795 36.0106 116.211 35.6564 118.256 34.5544L116.762 30.2251Z" fill="white"/>
    <path d="M133.262 15.5057C130.077 15.5057 127.796 17.08 126.223 19.3627V15.7812H121.032V35.4202H126.223V29.0838C126.223 23.6132 128.425 20.6615 133.223 20.6615H134.363L134.639 15.6632C134.167 15.5057 133.774 15.5057 133.262 15.5057Z" fill="white"/>
    <path d="M140.762 6.80792C138.914 6.80792 137.616 8.26412 137.616 9.9171C137.616 11.6488 138.914 12.9476 140.762 12.9476C142.571 12.9476 143.908 11.6488 143.908 9.9171C143.908 8.26412 142.571 6.80792 140.762 6.80792ZM143.358 35.4202V15.7812H138.167V35.4202H143.358Z" fill="white"/>
    <path d="M165.385 31.1697H154.374L164.913 19.2446V15.7812H148.239V20.0318H158.503L147.964 32.0355V35.4202H165.385V31.1697Z" fill="white"/>
    <path d="M178.275 15.309C172.416 15.309 167.579 19.4414 167.579 25.6204C167.579 31.7994 172.416 35.9318 178.275 35.9318C184.017 35.9318 188.854 31.7994 188.854 25.6204C188.854 19.4414 184.017 15.309 178.275 15.309ZM178.275 31.4845C175.444 31.4845 172.612 29.2806 172.612 25.6204C172.612 21.9602 175.444 19.7563 178.275 19.7563C180.989 19.7563 183.82 21.9602 183.82 25.6204C183.82 29.2806 180.989 31.4845 178.275 31.4845Z" fill="white"/>
    <path d="M204.135 15.1909C201.54 15.1909 199.495 16.0174 197.961 17.5523V15.7812H192.77V35.4202H197.961V26.7224C197.961 21.9209 199.888 19.953 202.837 19.953C205.236 19.953 206.809 21.8028 206.809 24.5578V35.4202H212V23.4558C212 18.5756 208.854 15.1909 204.135 15.1909Z" fill="white"/>
</svg>
    