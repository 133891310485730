import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Modern software house based in Poland';

  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    AOS.init();
    this.initSeo()
  }

  initSeo(): void {
    this.metaService.addTag( { name:'description', content:"As a software house, we provide UX/UI design services and full-stack engineering solutions that will suit your business."});
    this.metaService.addTag( { name:'robots', content:"index, archive"});
    this.metaService.addTag( { name:'canonical', content:"https://netrizon.eu"});
  }
  
}
