<div
    class="fixed mt-5 top-0 left-0 right-0 w-full mx-auto py-5 duration-300 z-10"
    [ngClass]="{'bg-gradient-to-b from-black mt-0 bg-opacity-80': scrolled}">
    <div class="container mx-auto">
        <nav class="flex justify-between items-center px-5 lg:px-0">
            <div class="flex">
                <a href="#">
                    <app-logo></app-logo>
                </a>
            </div>
            <div class="hidden md:flex">
                <ul class="flex gap-20">
                    <li>
                        <a pageScroll href="#about" class="flex flex-col py-2 text-zinc-400 hover:text-white hover:-translate-y-1 duration-200 group relative">
                            About
                            <span class="absolute bottom-0 w-0 h-px bg-blue-600 group-hover:w-full duration-500">&nbsp;</span>
                        </a>
                    </li>
                    <li>
                        <a pageScroll href="#services" class="flex flex-col py-2 text-zinc-400 hover:text-white hover:-translate-y-1 duration-200 group relative">
                            Services
                            <span class="absolute bottom-0 w-0 h-px bg-blue-600 group-hover:w-full duration-500">&nbsp;</span>
                        </a>
                    </li>
                    <li>
                        <a pageScroll href="#technologies" class="flex flex-col py-2 text-zinc-400 hover:text-white hover:-translate-y-1 duration-200 group relative">
                            Technologies
                            <span class="absolute bottom-0 w-0 h-px bg-blue-600 group-hover:w-full duration-500">&nbsp;</span>
                        </a>
                    </li>
                    <li>
                        <a pageScroll href="#process" class="flex flex-col py-2 text-zinc-400 hover:text-white hover:-translate-y-1 duration-200 group relative">
                            Process
                            <span class="absolute bottom-0 w-0 h-px bg-blue-600 group-hover:w-full duration-500">&nbsp;</span>
                        </a>
                    </li>
                    <!-- <li>
                      <a [routerLink]="['/case-studies']" class="flex flex-col py-2 text-zinc-400 hover:text-white hover:-translate-y-1 duration-200 group relative">
                        Case studies
                        <span class="absolute bottom-0 w-0 h-px bg-blue-600 group-hover:w-full duration-500">&nbsp;</span>
                      </a>
                    </li> -->
                    <li>
                        <a [routerLink]="['/recruitment']" routerLinkActive="text-blue-600"
                            class="flex flex-col py-2 text-zinc-400 hover:text-white hover:-translate-y-1 duration-200 group relative">
                            Careers
                            <span class="absolute bottom-0 w-0 h-px bg-blue-600 group-hover:w-full duration-500">&nbsp;</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="flex justify-end xl:w-40">
                <a href="mailto:hello@netrizon.eu" class="flex group relative py-3 px-6 z-0">
                    Contact us
                    <div
                        class="absolute right-0 top-0 bottom-0 h-full w-1/4 bg-blue-600 duration-200 group-hover:w-full rounded -z-1">
                        &nbsp;</div>
                </a>
            </div>
        </nav>
    </div>
</div>
